<template>
  <div class=" ">
    <div class="status-badge-container text-center ">
      <CButton size="" v-if="variant=='button'"
      :color="getColor(item)"
      @click="handleClick"
      class="status-badge center p-1">
        <strong>{{ getLabel(item) }}</strong>
      </CButton>
      <CBadge :color="getColor(item)" v-else
      size="xl" 
      class="status-badge center"
      @click="handleClick"
        >{{  getLabel(item) }}
      </CBadge>
      
    </div>
    <div class="timestamp  text-center">
        <span>{{ getTimestamp(item) }}</span>
        </div>
    </div>

</template>

<style scoped>

.timestamp {
  min-width: 100%;
  width: 100%;
}
.status-badge-container {
  margin: auto;
  margin-top: auto;
  margin-bottom: auto;
  width: 50%;
  padding: 1px;
  padding-top: 3px;
}
.status-badge {
  padding: 0.5rem;
  border-radius: 15px;
  text-align: center;
  font-size: 14px;
  min-width: 100px;
}
</style>

<script>
import moment from "moment";
const NO_ALERT_MESSAGE = "No Alerts"; 

export default {
  name: "AlarmStatusBadge",
  props: {
    variant:{
      type: String, 
      default: "button", 
    }, 
    item: {
      type: Object // Expects Alarm Status Object 
    },
    timestamp:{
      type: [Object, Date], 
    },
    humanize:{
      type: Boolean, 
      default: true, 
    }
  },
  computed:{
    isAlarming(){
      return this.item.status==="alarming";
},

  }, 
  data() {
    return {};
  },
  methods: {
     
    getMessage(item){
      return item.message? item.message: ""; 
    }, 
    getLabel(item){
      let label = item.status; 
      switch(item.status){
        default:
          label = item.status; 
          break;
          case("alarming"):
          label = this.getAlertLabel(item); 
          break;
          case("stopped"):
          label = NO_ALERT_MESSAGE
          break;
          case("working"):
          case("idle"):
          label =NO_ALERT_MESSAGE
          break;
      }
      return label; 
    }, 
    getTimestamp(item) {
      let timestamp; 
      if(this.timestamp)
        timestamp = this.timestamp;
      else if(item.timestamp){
        timestamp = item.timestamp; 
      }
      if(!timestamp){
        return ""; 
      }
      if(this.humanize){
        let duration = new Date(Date.now()) - timestamp; 
        let temp = moment.duration(duration, "ms").humanize();
        return `${temp} ago`;
      }
      else{
        let temp = moment(timestamp).format("MMM DD hh:mm:ss a z"); 
        return temp; 
      }
      


    },
    handleClick(e) {

      let sender = e;
      this.$emit("click", this.item, sender); 
    },
    getAlertLabel(item){
      let timestamp = item.timestamp;
      if(this.timestamp)
        timestamp = this.timestamp;
      else if(item.timestamp){
        timestamp = item.timestamp; 
      }
      let duration = new Date(Date.now()) - timestamp; 
      if(duration>=30000){
        return "alarmed"
      }
      return item.status; 
    },
    getColor(item) {
      let color = "";
      switch (item.status) {
        case "alarming":
          color = "danger";
          break;
          case "warning": 
          color = "warning"; 
          break;
        case "idle":
          color = "success";
          break;
        case "moving":
          color = "success";
          break;
        case "stopped":
          color = "secondary";
          break;
      }
      return color;
    }
  }
};
</script>
