

<script>
export default {
    name:"CFormInputBase", 
    props: {
    label: {
        type: String,
    },
    isValid: {
      type: Boolean,
      default: undefined,
    },
    invalidMessage:{
        type: String, 
    },
    required: {
      type: Boolean,
      default: false,
    },
    requiredMessage: {
      type: String,
      default: "*required",
    },
    tooltip: { type: String | Object },
  },
    data(){
        
    },
    computed:{

    }
}
</script>