var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: " " }, [
    _c(
      "div",
      { staticClass: "status-badge-container text-center " },
      [
        _vm.variant == "button"
          ? _c(
              "CButton",
              {
                staticClass: "status-badge center p-1",
                attrs: { size: "", color: _vm.getColor(_vm.item) },
                on: { click: _vm.handleClick }
              },
              [_c("strong", [_vm._v(_vm._s(_vm.getLabel(_vm.item)))])]
            )
          : _c(
              "CBadge",
              {
                staticClass: "status-badge center",
                attrs: { color: _vm.getColor(_vm.item), size: "xl" },
                on: { click: _vm.handleClick }
              },
              [_vm._v(_vm._s(_vm.getLabel(_vm.item)) + " ")]
            )
      ],
      1
    ),
    _c("div", { staticClass: "timestamp  text-center" }, [
      _c("span", [_vm._v(_vm._s(_vm.getTimestamp(_vm.item)))])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }