<template>
  <CCallout :color="getControlColor()" @click="handleClick">
    <CCardBody class="pt-0 pb-0">
      <CRow class="no-gutters">
        <CCol col="2" class="p-0 ">
          <CRow>
            <div>
              <TruckInfoCard :name="item.name" :device="item.device" :connected="getOnlineState(item)"
                :status="getTruckStatus(item)"></TruckInfoCard>
            </div>
          </CRow>
        </CCol>
        <CCol class="">
          <CRow >
            <CCol class="pr-0 pb-0 mb-0">
              <CRow class="mt-4 pb-0">
                <CCol class="pb-0">
                  <PressureDisplayWidget class="widget  " :show-value="false" :color="getDisplayColor(item)"
                    :value="pressure" :units="unit" :dataPoints="dataPoints" :labels="labels">
                  </PressureDisplayWidget>

                </CCol>
              </CRow>
              <CRow class="pb-0">
                <CCol>
                  <div class="message text-center p-0 m-0">
                    <CBadge class="message text-center p-0 m-0">
                      <span>{{ getMessage(item) }}</span>
                    </CBadge>
                  </div>
                </CCol>
              </CRow>


            </CCol>
            <CCol lg="3" md="3" sm="12" xs="12" class="mt-md-4">

              <span class="float-center mr-2">
                <AlarmStatusBadge :item="item" :timestamp="item.timestamp" @click="handleClick">
                </AlarmStatusBadge>
              </span>
            </CCol>
          </CRow>
        </CCol>
      </CRow>
    </CCardBody>


  </CCallout>
</template>

<style>
.truck-status-card-container {

  border-color: var(--secondary);
  border: 10px;
  border-radius: 10px;

  background-color: var(--secondary);
}

.truck-status-card-container-online {

  border-color: var(--success);
  border: 10px;
  border-radius: 10px;

  background-color: var(--success);
}


.widget {
  max-height: 100px;
}

.icon-container {
  margin: auto;
  padding: 1rem;
  max-height: 20px;
}

.icon__style {
  max-height: 50px;
  min-height: 20px;
}

.status-badge-container {
  margin: auto;
  margin-top: auto;
  margin-bottom: auto;
  width: 50%;
  padding: 1px;
  padding-top: 3px;
}

.device-text {
  word-wrap: normal;
}

.message {
  font-size: 14px;

}
</style>

<script>
import moment from "moment";
import PressureDisplayWidget from "@/widgets/PressureDisplayWidget.vue";
import AlarmStatusBadge from "@/components/badges/AlarmStatusBadge.vue";
import TruckInfoCard from "./TruckInfoCard.vue";
import CCallout from "@/components/base/CCallout.vue";
import { pressureThreshold } from "@/data/appData";
const DEFAULT_THRESHOLD = pressureThreshold; 

export default {
  name: "TruckStatusCard",
  components: {
    AlarmStatusBadge,
    PressureDisplayWidget,
    TruckInfoCard,
    CCallout
  },
  props: {
    item: {
      type: Object,
    },
    points: {
      type: Array,
    },
    threshold:{
      type: [String, Number], 
      default:()=>DEFAULT_THRESHOLD
    }
  },
  watch: {
    points(newVal, oldVal) {
      console.log(newVal, oldVal);
    },
  },
  computed: {
    connectionStatusMessage() {
      let ret = "";

      ret = this.getOnlineState(this.item);
      return ret;

    },
    isAlarming() {
      return this.item.status === "alarming";
    },
    pressure() {
      let pressure = this.item.pressure ? this.item.pressure : 0;
      if (Number.isNaN(pressure)) {
        return 1;
      }
      return pressure;
    },
    unit() {
      return "MAX PSI"
      //return this.item.unit ? this.item.unit : "PSI";
    },
    dataPoints() {
      if (!this.points) return [];
      let pressure = this.points.map((item) => {
        return item.pressure;
      });
      return pressure;
    },
    labels() {
      if (!this.points) return [];
      let times = this.points.map((item) => {
        return item.time;
      });
      return times;
    },
  },
  data() {
    return {
      displayColor: 'gradient-light',
    };
  },
  methods: {
    getControlColor() {
      if (this.getOnlineState(this.item) === "online") {
        return "var(--success)";
      }
      return "var(--secondary)"; // Offline color  
    },
    getMessage(item) {
      return item.message ? item.message : "";
    },
    getTruckStatus(item) {
      return item && item.fleetStatus ? item.fleetStatus.status : undefined;
    },
    getOnlineState(item) {
      let temp = "offline";
      if (item.connected) {
        temp = item.connected;
      }
      else if (item.deviceInfo.status && item.deviceInfo.status.last_connect) {
        let timestamp = item.deviceInfo.status.last_connect;
        let datetime = new Date(timestamp);

        // If Last connected within x hours then treat as online 
        if ((Date.now() - datetime) < 3600 * 1000) {
          // Update 
          temp = "online";
        }
      }


      return temp;
    },
    getSrc() {
      let device = this.item.device;
      let url =
        "http://192.168.1.231:3000/app/grafana/d-solo/AkkOM_F4k/new-dashboard?orgId=1&from=1670378583062&to=1670379301553&panelId=3";
      url += `deviceID=${device}`;
      return url;
    },
    handleClick(e) {
      this.$emit("click", this.item);
    },
    getTimestamp(item) {
      let duration = 1000;
      let temp = moment.duration(duration, "ms").humanize();
      return temp;
    },
    getDisplayColor(item) {
      let color = "gradient-light";
      if (item.max > this.threshold) {
        color = "gradient-light";
      }

      return color;
    },
    getColor(item) {
      let color = "primary";
      switch (item.status) {
        case "alarming":
          color = "danger";
          break;
        case "idle":
          color = "info";
          break;
        case "moving":
          color = "success";
          break;
        case "stopped":
          color = "secondary";
          break;
      }
      return color;
    },
  },
};
</script>
