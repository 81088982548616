var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "widget-label " },
    [
      _c(
        "div",
        {
          staticClass: "value-display d-flex d-sm-none float-right",
          class:
            _vm.value < _vm.threshold
              ? "value-display"
              : "value-display-warning",
          attrs: { "v-if": _vm.showValue }
        },
        [_c("h4", [_vm._v(_vm._s(_vm.value) + " " + _vm._s(_vm.unit))])]
      ),
      _c("CWidgetDropdown", {
        staticClass: "widget-label d-none d-sm-flex",
        attrs: {
          color: _vm.color,
          "label-color": "dark",
          header: _vm.title,
          text: _vm.subTitle
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _c(
                  "div",
                  {
                    staticClass: "value-display d-none d-sm-flex",
                    class:
                      _vm.value < _vm.threshold
                        ? "value-display"
                        : "value-display-warning",
                    attrs: { "v-if": _vm.showValue }
                  },
                  [
                    _c("h3", [
                      _vm._v(_vm._s(_vm.value) + " " + _vm._s(_vm.unit))
                    ])
                  ]
                )
              ]
            },
            proxy: true
          },
          {
            key: "footer",
            fn: function() {
              return [
                _c("CChartLineSimple", {
                  staticClass: "c-chart-brand d-none d-sm-flex",
                  attrs: {
                    "data-points": _vm.dataPoints,
                    "background-color": _vm.backgroundColor,
                    "border-color": _vm.borderColor,
                    "point-hover-background-color":
                      _vm.pointHoverBackgroundColor,
                    options: _vm.options,
                    label: _vm.label,
                    labels: _vm.labels
                  }
                })
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }