var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("CSelect", {
    staticClass: "pb-1 mb-0",
    attrs: { options: _vm.options, value: _vm.relativeTimeSelected },
    on: {
      "update:value": function($event) {
        _vm.relativeTimeSelected = $event
      }
    },
    scopedSlots: _vm._u(
      [
        {
          key: "prepend-content",
          fn: function() {
            return [
              _vm._t("default", [_c("CIcon", { attrs: { name: "cil-clock" } })])
            ]
          },
          proxy: true
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }