<template>
  <div class="widget-label ">

    <div :v-if="showValue" class="value-display d-flex d-sm-none float-right" :class="(value<threshold)?'value-display':'value-display-warning'" >
        <h4 >{{ value }} {{ unit }}</h4>
      </div>
  <CWidgetDropdown
    :color="color"
    
    label-color="dark"
    :header="title"
    :text="subTitle"

    class="widget-label d-none d-sm-flex"
  >
  
    <template #default>
      
      <div :v-if="showValue" class="value-display d-none d-sm-flex" :class="(value<threshold)?'value-display':'value-display-warning'" >
        <h3 >{{ value }} {{ unit }}</h3>
      </div>


    </template>
    <template #footer>
      <CChartLineSimple
        class="c-chart-brand d-none d-sm-flex"


        :data-points="dataPoints"
        :background-color="backgroundColor"
        :border-color="borderColor"
        :point-hover-background-color="pointHoverBackgroundColor"
        :options="options"
        :label="label"
        :labels="labels"
      />
    </template>
  </CWidgetDropdown>
</div>
</template>

<style>
.widget-label{
  
    margin-left: 0px;
    height: 90%;
    color: var(--dark);
}
.c-chart-brand {
  position: absolute;
  width: 100%;
  height: 100%;
}
.value-display{
  color : var(--info);
}
.value-display-warning{
  color : var(--danger);
}
</style>

<script>
/** A Base Element / View Model for displaying Data in a bargraph as a widget
 * Also will optionally display the averaged displayed value
 */

import { CChartLineSimple, CChartBarSimple } from "@/views/charts";

const DEFAULT_THRESHOLD = 2900; // TODO: Get from Config 

const defaultOptions = () => {
  return { elements: {} };
};

export default {
  name: "PressureDisplayWidget",
  components: {
    CChartLineSimple,
    CChartBarSimple
  },
  props: {
    pointed: { type: Boolean, default: true },
    labels: Array, // The labels to display for each value
    dataPoints: Array, // Must Be an Array of values
    label: {
      type: String, // Specifiy the background Color of the widget
      default: ""
    }, // A global Label for the Data Type
    pointHoverBackgroundColor: String,

    options: {
      type: Object,
      default: defaultOptions
    }, //
    title: {
      type: String, 
      default: ""
    }, // A g, // Give the Widget a Title
    subTitle: {
      type:String, 
      default: ""
    }, // Give the Widget a Sub-title
    units: String, //
    type: String, // Display a Bar Chart or Line Chart
    showValue: {
      type: Boolean,
      default: false
    }, // Flag to indicate if we want to show a value
    // TODO: Choose a Mode , Average, Max or last
    valueMode: {
      type: String, 
      default: "max"
    },
    precision: {
      type: Number,
      default: 1
    },
    color: {
      type: String, // Specifiy the background Color of the widget
      default: "info"
    },
    threshold:{
      type: Number, 
      default: DEFAULT_THRESHOLD, 
    }, 
    backgroundColor: {
      type:String, 
      default: "rgba(37, 150, 190,0.3)",
    }, // "rgba(255,255,255,.2)"
    borderColor:{ type:String, 
      default: "rgba(37, 150, 190,0.3)",
    }
  },
  data() {
    return {};
  },
  computed: {
    value() {
      // TODO: Select which mode to display
      let total = 0.0;
      let count = 0;

      

      if (this.dataPoints) {
        // Change the data mode here
        let ret =0.0; 
        switch(this.valueMode){
          case("max"):
            ret = this.calcMax(this.dataPoints);
            ret = ret.toFixed(this.precision); 
            break;
          case("last"):
          ret = this.calcLast(this.dataPoints);
          //ret = ret.toFixed(this.precision);
          break;


          default:
          case("average"):
          ret = this.calcAverage(this.dataPoints);
          ret = ret.toFixed(this.precision);
          break;
        }

         // this.calcMax(this.dataPoints); //this.calcAverage(this.dataPoints);
        return ret;
      } else {
        return 0;
      }
      //return this.calcAverage();
    },
    unit() {
      return this.units;
    }
  },
  methods: {

    calcLast(items){
      if (!items || items.length===0) 
        return 0.0;
      else if(items.length>3){
        // Average the last 3;
        let temp =0; 
        for(let i = 1; i<3; i++){
            temp += items[items.length-i]; 
        }
        return temp/3; 
      }
      else{
        return items[items.length]; 
      }
    }, 
    calcAverage(items) {
      let total = 0.0;
      let count = 0;
      if (!items || items.length===0) 
        return 0.0;
      for (var i = 0; i < items.length; i++) {
        let val = items[i];
        if (!isNaN(val)) {
          // Only Valid if value is a Number
          total += val;
          count += 1;
        }
      }

      return total / count;
    },
    calcMax(items) {

      if (!items || items.length===0) 
        return 0.0;
      let max = 0.0; 
      for (let i = 0; i < items.length; i++) {
        let val = items[i];
        if (!isNaN(val)&& val>max) {
          // Only Valid if value is a Number
          max = val; 
        }
      }

      return max;
    }
  }
};
</script>
