<template>
    <div class="callout-container " :style="classObj" >
        
        <CCard class="pt-0 pb-0 ml-1 my-0 border bd-callout " @click="handleClick"  :color="getControlColor()" >
            <slot name="default">
                <CCardBody class="pt-0 pb-0"></CCardBody>
            </slot>
        </CCard>
    </div>
</template>

<style scoped>
.callout-container {
    border: 10px;
    border-radius: 10px;
    min-height: 20px;
    
}
.callout-container-bg{
    background-color: red;
}
</style>

<script>
/** Card Wrapper Component to Emulate Bootstrap 5 a "Callout" Card View */
export default {
    name: "CCallout",
    props: {
        color: {
            type: String,
            default: "var(--warning)",
        },

    },
    computed: {
        classObj() {
 
            return {
                
                "background-color": this.color,


            }
        }
    },
    methods: {
        handleClick(e) {
            this.$emit("click", e);
        },
        getControlColor() {
            return this.color;
        }
    }

}
</script>
    
