var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CCallout",
    { attrs: { color: _vm.getControlColor() }, on: { click: _vm.handleClick } },
    [
      _c(
        "CCardBody",
        { staticClass: "pt-0 pb-0" },
        [
          _c(
            "CRow",
            { staticClass: "no-gutters" },
            [
              _c(
                "CCol",
                { staticClass: "p-0 ", attrs: { col: "2" } },
                [
                  _c("CRow", [
                    _c(
                      "div",
                      [
                        _c("TruckInfoCard", {
                          attrs: {
                            name: _vm.item.name,
                            device: _vm.item.device,
                            connected: _vm.getOnlineState(_vm.item),
                            status: _vm.getTruckStatus(_vm.item)
                          }
                        })
                      ],
                      1
                    )
                  ])
                ],
                1
              ),
              _c(
                "CCol",
                {},
                [
                  _c(
                    "CRow",
                    [
                      _c(
                        "CCol",
                        { staticClass: "pr-0 pb-0 mb-0" },
                        [
                          _c(
                            "CRow",
                            { staticClass: "mt-4 pb-0" },
                            [
                              _c(
                                "CCol",
                                { staticClass: "pb-0" },
                                [
                                  _c("PressureDisplayWidget", {
                                    staticClass: "widget  ",
                                    attrs: {
                                      "show-value": false,
                                      color: _vm.getDisplayColor(_vm.item),
                                      value: _vm.pressure,
                                      units: _vm.unit,
                                      dataPoints: _vm.dataPoints,
                                      labels: _vm.labels
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "CRow",
                            { staticClass: "pb-0" },
                            [
                              _c("CCol", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "message text-center p-0 m-0"
                                  },
                                  [
                                    _c(
                                      "CBadge",
                                      {
                                        staticClass:
                                          "message text-center p-0 m-0"
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.getMessage(_vm.item))
                                          )
                                        ])
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "CCol",
                        {
                          staticClass: "mt-md-4",
                          attrs: { lg: "3", md: "3", sm: "12", xs: "12" }
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "float-center mr-2" },
                            [
                              _c("AlarmStatusBadge", {
                                attrs: {
                                  item: _vm.item,
                                  timestamp: _vm.item.timestamp
                                },
                                on: { click: _vm.handleClick }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }