var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "callout-container ", style: _vm.classObj },
    [
      _c(
        "CCard",
        {
          staticClass: "pt-0 pb-0 ml-1 my-0 border bd-callout ",
          attrs: { color: _vm.getControlColor() },
          on: { click: _vm.handleClick }
        },
        [_vm._t("default", [_c("CCardBody", { staticClass: "pt-0 pb-0" })])],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }