
<template>
  <CSelect
    class="pb-1 mb-0"
    :options="options"
    :value.sync="relativeTimeSelected"
  >
    <template #prepend-content>
        <slot>
        <CIcon name="cil-clock" />
        </slot>
        </template>
  </CSelect>
</template>

<script>
const defaultTimes = () => {
  return [
    { id: "30m", value: "30m", label: "Last 30 Minutes" },
    { id: "1h", value: "1h", label: "Last 1 Hour" },
    { id: "8h", value: "8h", label: "Last 8 Hours" },
    { id: "24h", value: "24h", label: "Last 24 Hours" },
    { id: "3d", value: "3d", label: "Last 3 Days" },
  ];
};
export default {
  name: "CRelativeTimePicker",
  props: {
    options: {
      type: Array,
      default: defaultTimes,
    },
    value: {
      type: String || Object,
    },
  },
  data() {
    return {
      selectedID: undefined,
    };
  },
  computed: {
    relativeTimeSelected: {
      get() {
          return this.value; 
          //return this.selectedID; 

        let temp = this.relativeTimes.find((x) => x.id === this.selectedID);
        if (temp) return temp.value;
        return undefined;
      },
      set(value) {
        this.selectedID = value;
        //this.$emit("input:value", value);
        this.$emit("update:value", value);
      },
    },
  },
  methods: {},
};
</script>